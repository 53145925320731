import React from 'react';
import { Box, Text } from 'theme-ui';
import Image from '../../atoms/Image';
import { StyledPeopleTileQuote, StyledPeopleTileTitle } from './styles';
import { PeopleTileProps } from './types';

const PeopleTile: React.FC<PeopleTileProps> = ({
  name,
  profession,
  title,
  quote,
  image,
}) => (
  <Box variant="peopleTile.wrapper">
    <Image ratio={772 / 386} {...image} />
    <Box variant="peopleTile.content">
      <Text as="p" variant="peopleTile.tags">
        {name}, {profession}
      </Text>
      <StyledPeopleTileTitle as="h4" variant="peopleTile.title">
        {title}
      </StyledPeopleTileTitle>
      <StyledPeopleTileQuote as="q" variant="peopleTile.quote">
        {quote}
      </StyledPeopleTileQuote>
    </Box>
  </Box>
);

export default PeopleTile;
