import React from 'react';
import { Box, Grid } from 'theme-ui';
import { InfoGridListProps } from '../types';
import InfoGridItemContent from './content';

const InfoGridList: React.FC<InfoGridListProps> = ({
  items,
  columnsCount,
  columnGap,
  wide,
}) => (
  <Grid as="ul" columns={columnsCount} gap={columnGap} variant="infoGrid.list">
    {items.map(({ key, ...item }) => (
      <Box as="li" {...{ key }} variant="infoGrid.listItem">
        <InfoGridItemContent {...{ wide, ...item }} />
      </Box>
    ))}
  </Grid>
);

export default InfoGridList;
