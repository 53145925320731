/** @jsx jsx */
import { jsx, Box, Text } from 'theme-ui';
import { Link } from 'gatsby';
import { InfoBoxProps } from './types';

const InfoBox: React.FC<InfoBoxProps> = ({
  title,
  description,
  to,
  ctaLabel,
}) => (
  <Box>
    <Text as="h3" variant="h1" mb="0.5rem">
      {title}
    </Text>
    <Text as="p" variant="h4roman" mb={['1.25rem', '1.25rem', '2.5rem']}>
      {description}
    </Text>
    <Link {...{ to }} sx={{ variant: 'links.underlined' }}>
      {ctaLabel}
    </Link>
  </Box>
);

export default InfoBox;
