import React from 'react';
import { Box } from 'theme-ui';
import useStatefulRef from '../../../hooks/useStatefulRef';
import { BaseCarouselItem } from '../Carousel/types';
import { useDynamicHeightValue, useTranslateValueOnScroll } from './hooks';
import {
  StyledCarouselContent,
  StyledCarouselContentWrapper,
  StyledCarouselItem,
  StyledCarouselTitle,
} from './styles';
import { HorizontalCarouselProps } from './types';

function HorizontalCarousel<T extends BaseCarouselItem>({
  items,
  component: Component,
  sectionTitle,
}: HorizontalCarouselProps<T>): JSX.Element {
  const [containerRef, containerRefValue] = useStatefulRef<HTMLDivElement>();
  const [objectRef, objectRefValue] = useStatefulRef<HTMLDivElement>();

  const translateX = useTranslateValueOnScroll(containerRefValue);
  const dynamicHeight = useDynamicHeightValue(objectRefValue);

  return (
    <Box
      variant="horizontalCarousel.outerContainer"
      sx={{
        height: `${dynamicHeight}px`,
      }}
    >
      <Box variant="horizontalCarousel.inner" ref={containerRef}>
        <Box
          variant="horizontalCarousel.innerContainer"
          sx={{
            transform: `translateX(${translateX}px)`,
          }}
          ref={objectRef}
        >
          <StyledCarouselContent>
            {!!sectionTitle && (
              <StyledCarouselTitle as="h2" variant="horizontalCarousel.title">
                {sectionTitle}
              </StyledCarouselTitle>
            )}
            <StyledCarouselContentWrapper>
              {items.map(({ key, ...item }) => (
                <StyledCarouselItem {...{ key }}>
                  <Component {...item} />
                </StyledCarouselItem>
              ))}
            </StyledCarouselContentWrapper>
          </StyledCarouselContent>
        </Box>
      </Box>
    </Box>
  );
}

export default HorizontalCarousel;
