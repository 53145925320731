import React, { useCallback } from 'react';
import { Box, Container, Text } from 'theme-ui';
import Carousel from '../../molecules/Carousel';
import { TwitterCarouselContentProps, TwitterCarouselProps } from './types';
import RevealAnimationWrapper from '../../atoms/RevealAnimationWrapper';

const TwitterCarouselContent: React.FC<TwitterCarouselContentProps> = ({
  author,
  content,
  link,
}) => {
  const TextWrapper = useCallback(
    ({ children }) =>
      link ? (
        <a href={link} target="_blank" rel="noreferrer">
          {children}
        </a>
      ) : (
        children
      ),
    [link],
  );

  return (
    <Box variant="twitterCarousel.content">
      <TextWrapper>
        <Text variant="tags" as="h3" mb="1rem">
          {author}
        </Text>
        <Text variant="h4roman" as="div">
          {content}
        </Text>
      </TextWrapper>
    </Box>
  );
};

const TwitterCarousel: React.FC<TwitterCarouselProps> = ({ items }) => (
  <RevealAnimationWrapper>
    <Box variant="twitterCarousel.wrapper">
      <Container>
        <Carousel
          component={TwitterCarouselContent}
          slidesToShow={1}
          slidesToScroll={1}
          autoplay
          autoplaySpeed={6000}
          fade
          {...{ items }}
        />
      </Container>
    </Box>
  </RevealAnimationWrapper>
);

export default TwitterCarousel;
