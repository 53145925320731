import React from 'react';
import { Box, Flex, Text } from 'theme-ui';
import InfoGridList from './partials/list';
import InfoGridMedia from './partials/media';
import { StyledContentGrid } from './styles';
import { InfoGridProps } from './types';

const InfoGrid: React.FC<InfoGridProps> = ({
  title,
  items,
  columnsCount,
  columnGap,
  isHorizontal,
  media,
  reversed,
}) => (
  <Flex
    variant={isHorizontal ? 'infoGrid.wrapperHorizontal' : 'infoGrid.wrapper'}
  >
    {!!title && (
      <Box variant={isHorizontal ? 'infoGrid.boxHorizontal' : 'infoGrid.box'}>
        <Text as="h2" variant="h3" className="title">
          {title}
        </Text>
      </Box>
    )}
    <Box
      variant={
        isHorizontal && title ? 'infoGrid.boxHorizontal' : 'infoGrid.box'
      }
    >
      {media && !isHorizontal ? (
        <StyledContentGrid withVideo={!!media.url} {...{ reversed }}>
          <InfoGridMedia {...media} />
          <InfoGridList {...{ items, columnGap, columnsCount }} wide />
        </StyledContentGrid>
      ) : (
        <InfoGridList {...{ items, columnGap, columnsCount }} />
      )}
    </Box>
  </Flex>
);

export default InfoGrid;
