/* eslint-disable no-underscore-dangle */
import React, { useMemo } from 'react';
import { Box, Container } from 'theme-ui';
import { noop } from 'lodash';

import SEO from '../../atoms/SEO';
import Sections from './sections';
import InfoBanner from '../../organisms/InfoBanner';
import PeopleCarousel from '../../organisms/PeopleCarousel';
import TwitterCarousel from '../../organisms/TwitterCarousel';
import { prepareCaseStudyInfoBanner } from '../caseStudy/utils';

import { IPageData } from './types';
import {
  getCarouselItems,
  getCarouselQuotes,
  prepareInfoBannerPopup,
  prepareSlides,
} from './utils';
import TwitterSection from './sections/TwitterSection';
import DismissableSection from '../../organisms/DismissableSection';

const PageBody = ({ data, pageContext }: IPageData) => {
  const { page } = data;
  const { lastInsights } = pageContext;

  const twitterCarouselItems = useMemo(
    () => getCarouselItems(page?.carouselItems),
    [page.carouselItems],
  );
  const peopleCarousel = useMemo(
    () => getCarouselQuotes(page?.carouselQuotes?.[0]),
    [page?.carouselQuotes],
  );
  const infoBanner = useMemo(() => {
    // I'm taking the first one because it can be an array with only one element there
    const banner = page?.infoBanner?.[0];
    if (banner) {
      if (banner._type === 'infoBanner') {
        return {
          items: prepareCaseStudyInfoBanner([banner], 'h2'),
        };
      }
      return {
        items: prepareInfoBannerPopup([banner], 'h2'),
        modal: {
          title: banner.popupHeading,
          onSubmit: noop,
          formId: process.env.GATSBY_HUBSPOT_SUBSCRIBE_FORM_ID as string,
          portalId: process.env.GATSBY_HUBSPOT_PORTAL_ID as string,
        },
      };
    }
    return {};
  }, [page.infoBanner]);

  const slides = useMemo(
    () => (page.showSectionSlider ? prepareSlides(page.slides) : []),
    [page],
  );

  const sections = useMemo(
    () =>
      page.sections?.filter(Boolean).map((section) => {
        if (
          section._type === 'insightsSection' &&
          section.shouldIgnoreSelection
        ) {
          return {
            ...section,
            insights: lastInsights,
          };
        }
        return section;
      }),
    [page.sections, lastInsights],
  );

  return (
    <Box variant="pages.page.pageWrapper" className="page-wrapper">
      <Box variant="pages.page.pageWrapper.content">
        <SEO
          title={page.seoTitle}
          description={page.seoDescription}
          imageUrl={page?.seoImage?.asset?.fluid?.src}
        />
        {slides.length > 0 && <DismissableSection items={slides} />}
        <Container
          sx={{
            variant: page.hasSubmenu
              ? 'pages.page.wrapperSmall'
              : 'pages.page.wrapper',
          }}
        >
          <Sections sections={sections} />
        </Container>
        {peopleCarousel?.items?.length > 0 && (
          <PeopleCarousel
            items={peopleCarousel.items}
            title={peopleCarousel.title}
          />
        )}
        {infoBanner?.items && (
          <InfoBanner items={infoBanner.items} modal={infoBanner?.modal} />
        )}
        {twitterCarouselItems.length > 0 && (
          <TwitterCarousel items={twitterCarouselItems} />
        )}
        {page.showTwitterSection && <TwitterSection />}
      </Box>
    </Box>
  );
};

export default PageBody;
