/* eslint-disable no-underscore-dangle */
import React, { useMemo } from 'react';
import RevealAnimationWrapper from '../../../../atoms/RevealAnimationWrapper';
import ContactPageBoxList from '../../../contact/partials/BoxList';
import PageSection from '../../../Section';
import { IContactCards } from '../../types';
import { getContactCards } from '../../utils';

type IProps = {
  section: IContactCards;
};

const ContactCards: React.FC<IProps> = ({ section }) => {
  const items = useMemo(() => getContactCards(section.contactCards), [section]);
  return (
    <PageSection hasSmallSpacing>
      <RevealAnimationWrapper>
        <ContactPageBoxList items={items} />
      </RevealAnimationWrapper>
    </PageSection>
  );
};

export default ContactCards;
