/* eslint-disable no-underscore-dangle */
import React from 'react';
import HeadingContent from './HeadingContent';
import VideoSection from './VideoSection';
import ContentButton from './ContentButton';
import Insights from './Insights';
import CaseStudies from './CaseStudies';
import { ISectionsProps } from '../types';
import StickyList from './StickyList';
import TeamMembers from './TeamMembers';
import ContentText from './ContentText';
import ListsWithSublists from './ListsWithSublists';
import RelatedPagesSection from './RelatedPages';
import ImageSection from './Image';
import Jobs from './Jobs';
import ContactCards from './ContactCards';
import MapList from './MapList';

const Sections: React.FC<ISectionsProps> = ({ sections = [] }) => (
  <>
    {sections.map((section) => (
      <React.Fragment key={section._key}>
        {section._type === 'headingContentHighlight' && (
          <HeadingContent section={section} />
        )}
        {section._type === 'singleImage' && <ImageSection section={section} />}
        {(section._type === 'video' || section._type === 'videoUrl') && (
          <VideoSection section={section} />
        )}
        {section._type === 'contentText' && <ContentText section={section} />}
        {section._type === 'contentButton' && (
          <ContentButton section={section} />
        )}
        {section._type === 'caseStudiesSection' && (
          <CaseStudies section={section} />
        )}
        {section._type === 'insightsSection' && <Insights section={section} />}
        {section._type === 'stickyList' && <StickyList section={section} />}
        {section._type === 'listsWithSublists' && (
          <ListsWithSublists section={section} />
        )}
        {section._type === 'relatedPages' && (
          <RelatedPagesSection section={section} />
        )}
        {section._type === 'teamMembersSection' && (
          <TeamMembers section={section} />
        )}
        {section._type === 'jobsSection' && <Jobs section={section} />}
        {section._type === 'contactCards' && <ContactCards section={section} />}
        {section._type === 'mapList' && <MapList section={section} />}
      </React.Fragment>
    ))}
  </>
);

export default Sections;
