import { AnimatePresence, motion } from 'framer-motion';
import React, { useCallback, useState } from 'react';
import { Box, Button, Container, Flex } from 'theme-ui';
import useHasMounted from '../../../hooks/useHasMounted';
import useToggle from '../../../hooks/useToggle';
import Carousel from '../../molecules/Carousel';
import DismissableSectionContent from './content';
import {
  DismissableSectionNavigationProps,
  DismissableSectionProps,
} from './types';

const AnimatedWrapperBox = motion.custom(Box);

const Navigation: React.FC<DismissableSectionNavigationProps> = ({
  dots,
  onClick,
  isLight,
}) => (
  <Box
    variant="dismissableSection.navigation"
    className={isLight ? 'is-light' : ''}
  >
    <Container>
      <Flex variant="dismissableSection.navigation.content">
        {dots && (
          <Box as="ul" className="slick-dots">
            {dots}
          </Box>
        )}
        <Box variant="dismissableSection.navigation.action">
          <Button variant="underlinedAccent" {...{ onClick }}>
            Close
          </Button>
        </Box>
      </Flex>
    </Container>
  </Box>
);

const DismissableSection: React.FC<DismissableSectionProps> = ({ items }) => {
  const [isVisible, { toggleOff }] = useToggle({ defaultToggleState: true });
  const hasMounted = useHasMounted();

  const sessionStorageValue =
    typeof window !== 'undefined'
      ? sessionStorage.getItem('isHeroModalSeen') !== 'true'
      : true;

  const [shouldShowModal, setShouldShowModal] = useState(sessionStorageValue);
  const [activeSlide, setActiveSlide] = useState(0);

  const handleClose = useCallback(() => {
    toggleOff();
    setShouldShowModal(false);
    sessionStorage.setItem('isHeroModalSeen', 'true');
  }, [toggleOff]);

  return hasMounted ? (
    <AnimatePresence initial={false}>
      {isVisible && shouldShowModal && (
        <AnimatedWrapperBox
          variant="dismissableSection.wrapper"
          initial={{ height: 0 }}
          animate={{ height: 'auto' }}
          exit={{ height: 0 }}
          transition={{ ease: 'easeOut' }}
        >
          <Carousel
            component={DismissableSectionContent}
            slidesToShow={1}
            slidesToScroll={1}
            fade
            beforeChange={(_, nextSlide) => setActiveSlide(nextSlide)}
            autoplay
            autoplaySpeed={6000}
            appendDots={(dots) => (
              <Navigation
                {...{ dots }}
                onClick={handleClose}
                isLight={items[activeSlide].whiteColorText}
              />
            )}
            {...{ items }}
          />
          {items.length < 2 && (
            <Navigation
              onClick={handleClose}
              isLight={items[activeSlide].whiteColorText}
            />
          )}
        </AnimatedWrapperBox>
      )}
    </AnimatePresence>
  ) : null;
};

export default DismissableSection;
