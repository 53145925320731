import React, { useMemo } from 'react';
import RevealAnimationWrapper from '../../../../atoms/RevealAnimationWrapper';
import { IListsWithSublists } from '../../types';
import { getListsWithSublistsItems } from '../../utils';
import PageSection from '../../../Section';
import InfoGrid from '../../../../molecules/InfoGrid';

type IProps = {
  section: IListsWithSublists;
};

const ListsWithSublists: React.FC<IProps> = ({ section }) => {
  const items = useMemo(() => getListsWithSublistsItems(section.listItems), [
    section.listItems,
  ]);

  return (
    <PageSection>
      <RevealAnimationWrapper>
        <InfoGrid
          title={section.title}
          items={items}
          columnsCount={[1, 2, 2, 2]}
          columnGap={[
            '5rem',
            '5rem 3.125rem',
            '5rem 3.125rem',
            '10rem 8.625rem',
          ]}
        />
      </RevealAnimationWrapper>
    </PageSection>
  );
};

export default ListsWithSublists;
