/* eslint-disable no-underscore-dangle */
import React, { useMemo } from 'react';
import { Box } from 'theme-ui';
import RevealAnimationWrapper from '../../../../atoms/RevealAnimationWrapper';
import Video from '../../../../molecules/Video';
import PageSection from '../../../Section';
import { IVideoMP4, IVideoUrl } from '../../types';

type IProps = {
  section: IVideoUrl | IVideoMP4;
};

const VideoSection: React.FC<IProps> = ({ section }) => {
  const videoUrl = useMemo(() => {
    const url =
      section._type === 'video'
        ? section?.video?.asset?.url
        : section?.videoUrl;

    return url;
  }, [section]);

  return videoUrl ? (
    <PageSection as="div" hasSmallSpacing>
      <Box variant="pages.page.animated">
        <RevealAnimationWrapper cascade>
          <Video
            url={videoUrl}
            image={{ fluid: section?.placeholderImage?.asset?.fluid }}
            autoPlay={!!section.isAutoplay}
          />
        </RevealAnimationWrapper>
      </Box>
    </PageSection>
  ) : null;
};

export default VideoSection;
