import React from 'react';
import { Box, Button, Text } from 'theme-ui';
import useToggle from '../../../../hooks/useToggle';
import RevealAnimationWrapper from '../../../atoms/RevealAnimationWrapper';
import TeamMemberModal from '../../Modal/templates/TeamMemberModal';
import { InfoGridItemProps } from '../types';

const InfoGridItemContent: React.FC<InfoGridItemProps> = ({
  title,
  content,
  wide,
  modal,
}) => {
  const [isOpen, { toggleOn, toggleOff }] = useToggle();

  return (
    <>
      <RevealAnimationWrapper>
        <Box variant={wide ? 'infoGrid.itemWide' : 'infoGrid.item'}>
          {modal ? (
            <Button
              onClick={toggleOn}
              variant="infoGrid.buttonHeading"
              className={isOpen ? 'is-active' : ''}
            >
              <Text as="span" variant="infoGrid.buttonHeading.text">
                {title}
              </Text>
            </Button>
          ) : (
            <Text as="h3" variant="h2">
              {title}
            </Text>
          )}
          <Box variant="infoGrid.content">
            {typeof content === 'string' ? (
              <Text as="p" variant="bodySmall">
                {content}
              </Text>
            ) : (
              <Box as="ul" variant="infoGrid.content.list">
                {(content || []).map((item) => (
                  <Box as="li" key={item} variant="infoGrid.content.listItem">
                    <Text as="p" variant="bodySmall">
                      {item}
                    </Text>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </Box>
      </RevealAnimationWrapper>
      {isOpen && modal && (
        <TeamMemberModal {...{ isOpen, ...modal }} onClose={toggleOff} />
      )}
    </>
  );
};

export default InfoGridItemContent;
