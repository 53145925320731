import React from 'react';
import { Flex } from 'theme-ui';
import RevealAnimationWrapper from '../../atoms/RevealAnimationWrapper';
import RelatedPageTile from '../../molecules/RelatedPageTile';
import { RelatedPagesProps } from './types';

const RelatedPages: React.FC<RelatedPagesProps> = ({ items }) => (
  <Flex as="ul" variant="relatedPages.list">
    {items.map(({ key, ...item }) => (
      <Flex as="li" {...{ key }} variant="relatedPages.listItem">
        <RevealAnimationWrapper>
          <RelatedPageTile {...item} />
        </RevealAnimationWrapper>
      </Flex>
    ))}
  </Flex>
);

export default RelatedPages;
