/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Link, Text } from 'theme-ui';
import { generatePath } from '../../../utils/generatePath';

import routes from '../../../routes';
import { ImagesGridItem } from '../../molecules/ImagesGrid/types';
import { RelatedWorkItem } from '../../organisms/RelatedWork/types';
import { ICasyStudy } from '../../../apiTypes';

import { CaseStudyPageProps } from './types';
import { InfoBannerItem } from '../../organisms/InfoBanner/types';

export const getCaseStudyItems01 = (
  data: CaseStudyPageProps['data'],
): ImagesGridItem[] => [
  {
    image: {
      src: data.picCaseStudy01.childImageSharp.fluid.src,
    },
    key: '1',
  },
  {
    image: {
      fluid: data.picCaseStudy02.childImageSharp.fluid,
    },
    small: true,
    key: '2',
  },
  {
    image: {
      fluid: data.picCaseStudy03.childImageSharp.fluid,
    },
    small: true,
    key: '3',
  },
];

export const getCaseStudyItems02 = (
  data: CaseStudyPageProps['data'],
): ImagesGridItem[] => [
  {
    image: {
      fluid: data.picCaseStudy04.childImageSharp.fluid,
    },
    small: true,
    key: '4',
  },
  {
    image: {
      fluid: data.picCaseStudy05.childImageSharp.fluid,
    },
    small: true,
    key: '5',
  },
  {
    image: {
      fluid: data.picCaseStudy06.childImageSharp.fluid,
    },
    small: true,
    key: '6',
  },
  {
    image: {
      fluid: data.picCaseStudy07.childImageSharp.fluid,
    },
    small: true,
    key: '7',
  },
];

export const getRelatedWorkItems = (
  data: CaseStudyPageProps['data'],
): RelatedWorkItem[] => [
  {
    category: 'U+I',
    title: 'Lorem ipsum sans dial',
    to: routes.CASE_STUDY,
    dark: true,
    image: {
      fluid: data.picRelatedWork01.childImageSharp.fluid,
    },
    key: '1',
  },
  {
    category: 'JTC',
    title: 'The promise of predictability',
    to: routes.CASE_STUDY,
    image: {
      fluid: data.picRelatedWork02.childImageSharp.fluid,
    },
    key: '2',
  },
];

export const prepareCaseStudyWork = (
  works: ICasyStudy['works'] = [],
): RelatedWorkItem[] => {
  const list = works
    // eslint-disable-next-line no-prototype-builtins
    .filter((work) => work?.slug)
    .map((work) => ({
      category: work.clientName,
      title: work.title,
      to: generatePath(routes.CASE_STUDY_SINGLE, {
        slug: work?.slug?.current,
      }),
      dark: work.darkBackground,
      image: {
        fluid: work.mainImage?.asset?.fluid,
      },
      key: work.title,
    }));

  return list;
};

export const prepareCaseStudyInfoBanner = (
  infoBanner: ICasyStudy['infoBanner'] = [],
  textVariant = 'h4roman',
): InfoBannerItem[] => {
  const info = infoBanner
    .map((banner) => {
      const link = banner?.link?.[0];

      const props =
        link?._type === 'addressEmail'
          ? {
              href: `mailto:${link.email}`,
              target: '_blank',
              rel: 'noreferrer',
            }
          : {
              href: link?.link,
              target: link?.openNewTab ? '_blank' : '',
              rel: link?.nofollow ? 'noreferrer' : '',
            };

      return [
        {
          key: '1',
          title: banner.title,
          content: (
            <Text as="p" variant={textVariant}>
              {banner.quote}
            </Text>
          ),
        },
        {
          key: '2',
          title: banner.subtitle,
          content: (
            <Link {...props} variant="links.underlinedLight">
              {link?.title}
            </Link>
          ),
        },
      ];
    })
    .flat();
  return info;
};
