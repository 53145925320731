import React, { useLayoutEffect, useState } from 'react';
import { useResponsiveValue } from '@theme-ui/match-media';
import { Box, Container, Text } from 'theme-ui';
import HorizontalCarousel from '../../molecules/HorizontalCarousel';
import PeopleTile from '../../molecules/PeopleTile';
import { PeopleCarouselProps } from './types';
import Carousel from '../../molecules/Carousel';
import RevealAnimationWrapper from '../../atoms/RevealAnimationWrapper';
import useHasMounted from '../../../hooks/useHasMounted';

const PeopleCarousel: React.FC<PeopleCarouselProps> = ({ items, title }) => {
  const hasMounted = useHasMounted();
  const isDesktop = useResponsiveValue([false, false, false, true], {
    defaultIndex: 0,
  });
  const [isDesktopView, setisDesktopView] = useState(isDesktop);

  useLayoutEffect(() => {
    setisDesktopView(isDesktop);
  }, [isDesktop]);

  return (
    <RevealAnimationWrapper>
      <Box variant="peopleCarousel.wrapper">
        {isDesktopView && hasMounted ? (
          <HorizontalCarousel
            component={PeopleTile}
            sectionTitle={title}
            {...{ items }}
          />
        ) : (
          <Container>
            {!!title && (
              <Text as="h2" variant="peopleCarousel.title">
                {title}
              </Text>
            )}
            <Carousel
              component={PeopleTile}
              slidesToShow={2}
              slidesToScroll={2}
              responsive={[
                {
                  breakpoint: 1025,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                  },
                },
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ]}
              {...{ items }}
            />
          </Container>
        )}
      </Box>
    </RevealAnimationWrapper>
  );
};

export default PeopleCarousel;
