import { useLayoutEffect, useState } from 'react';
import { handleDynamicHeight } from './utils';

export const useTranslateValueOnScroll = (
  element: HTMLDivElement | null,
): number => {
  const [translateX, setTranslateX] = useState(0);

  useLayoutEffect(() => {
    const handleTranslateValueChange = () => {
      if (!element) return;

      const offsetTop = -element.offsetTop;
      setTranslateX(offsetTop);
    };

    window.addEventListener('scroll', handleTranslateValueChange);

    return (): void => {
      window.removeEventListener('scroll', handleTranslateValueChange);
    };
  }, [element]);

  return translateX;
};

export const useDynamicHeightValue = (
  element: HTMLDivElement | null,
): number => {
  const [dynamicHeight, setDynamicHeight] = useState(0);

  useLayoutEffect(() => {
    const resizeHandler = () => {
      if (element) {
        handleDynamicHeight(element, setDynamicHeight);
      }
    };

    if (element) {
      handleDynamicHeight(element, setDynamicHeight);
    }
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, [element]);

  return dynamicHeight;
};
