import React from 'react';
import { Box } from 'theme-ui';
import MapBox from '../../molecules/MapBox';
import { MapBoxListProps } from './types';

const MapBoxList: React.FC<MapBoxListProps> = ({ items }) => (
  <Box as="ul" variant="mapBoxList.wrapper">
    {items.map(({ key, ...item }) => (
      <Box as="li" {...{ key }} variant="mapBoxList.item">
        <MapBox {...item} />
      </Box>
    ))}
  </Box>
);

export default MapBoxList;
