import React, { useMemo } from 'react';
import { Box } from 'theme-ui';
import { IJobsSection } from '../../types';
import { getJobs } from '../../utils';
import PageSection from '../../../Section';
import InfoBoxList from '../../../../organisms/InfoBoxList';

type IProps = {
  section: IJobsSection;
};

const Jobs: React.FC<IProps> = ({ section }) => {
  const items = useMemo(() => getJobs(section.jobs), [section.jobs]);

  return (
    <PageSection>
      <Box
        sx={{
          maxWidth: ['', '', '48.125rem'],
        }}
      >
        <InfoBoxList items={items} />
      </Box>
    </PageSection>
  );
};

export default Jobs;
