/* eslint-disable no-underscore-dangle */
import React, { useMemo } from 'react';
import RevealAnimationWrapper from '../../../../atoms/RevealAnimationWrapper';
import { IStickyListSection } from '../../types';
import { getMediaItem, getStickyListItems } from '../../utils';
import PageSection from '../../../Section';
import InfoGrid from '../../../../molecules/InfoGrid';

type IProps = {
  section: IStickyListSection;
};

const StickyList: React.FC<IProps> = ({ section }) => {
  const items = useMemo(() => getStickyListItems(section.list), [section.list]);

  const media = useMemo(() => getMediaItem(section?.mediaItem), [
    section.mediaItem,
  ]);

  return (
    <PageSection>
      <RevealAnimationWrapper>
        <InfoGrid
          title={section.title}
          items={items}
          isHorizontal={!media}
          reversed={section.isReverseColumn}
          columnsCount={media?.url ? [1, 2, 1] : [1]}
          columnGap={['5rem', '5rem', '10rem']}
          media={media}
        />
      </RevealAnimationWrapper>
    </PageSection>
  );
};

export default StickyList;
