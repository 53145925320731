import React from 'react';
import { Box } from 'theme-ui';
import RevealAnimationWrapper from '../../atoms/RevealAnimationWrapper';
import InfoBox from '../../molecules/InfoBox';
import { InfoBoxListProps } from './types';

const InfoBoxList: React.FC<InfoBoxListProps> = ({ items }) => (
  <Box as="ul" variant="infoBoxList.list">
    {items.map(({ key, ...item }) => (
      <Box as="li" {...{ key }} variant="infoBoxList.listItem">
        <RevealAnimationWrapper>
          <InfoBox {...item} />
        </RevealAnimationWrapper>
      </Box>
    ))}
  </Box>
);

export default InfoBoxList;
