import React from 'react';
import { Box, Text } from 'theme-ui';
import RevealAnimationWrapper from '../../atoms/RevealAnimationWrapper';

import { SmallHeroProps } from './types';

const HeroSmall: React.FC<SmallHeroProps> = ({ heading1, heading2 }) => (
  <RevealAnimationWrapper>
    <Box variant="heroSmall.wrapper">
      <Text as="h1" variant="h2">
        {heading1}
      </Text>
      {!!heading2 && (
        <Text as="h2" variant="h4roman" mt={['2.5rem', '2.5rem', '5rem']}>
          {heading2}
        </Text>
      )}
    </Box>
  </RevealAnimationWrapper>
);

export default HeroSmall;
