/* eslint-disable no-underscore-dangle */
import React, { useMemo } from 'react';
import PageSection from '../../../Section';
import RevealAnimationWrapper from '../../../../atoms/RevealAnimationWrapper';
import Image from '../../../../atoms/Image';
import { ImagePropsType } from '../../../../atoms/Image/types';
import { ISingleImage } from '../../types';

type IProps = {
  section: ISingleImage;
};

const ImageSection: React.FC<IProps> = ({ section }) => {
  const image = useMemo<ImagePropsType>(() => section?.mainImage?.asset, [
    section,
  ]);

  return (
    <PageSection hasSmallSpacing>
      <RevealAnimationWrapper>
        <Image {...image} ratio={1552 / 872} />
      </RevealAnimationWrapper>
    </PageSection>
  );
};

export default ImageSection;
