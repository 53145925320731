/* eslint-disable no-underscore-dangle */
import React from 'react';
import PlainTextWithHighlight from '../../../../custom/PlainTextWithHighlight';
import Hero from '../../../../molecules/Hero';
import PageSection from '../../../Section';
import { IHeadingContentHighlight } from '../../types';

type IProps = {
  section: IHeadingContentHighlight;
};

const HeadingVideo: React.FC<IProps> = ({ section }) => (
  <PageSection hasSmallSpacing>
    <Hero
      heading1={section.heading}
      heading2={<PlainTextWithHighlight blocks={section._rawBody} />}
    />
  </PageSection>
);

export default HeadingVideo;
