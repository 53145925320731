import styled from '@emotion/styled';
import { Text } from 'theme-ui';

export const StyledPeopleTileTitle = styled(Text)`
  @media screen and (min-width: 80rem) and (max-height: 770px) {
    font-size: calc(20px + (52 - 20) * ((100vw - 1280px) / (1600 - 1280)));
  }
`;

export const StyledPeopleTileQuote = styled(Text)`
  @media screen and (min-width: 80rem) and (max-height: 770px) {
    font-size: calc(16px + (20 - 16) * ((100vw - 1280px) / (1600 - 1280)));
  }
`;
