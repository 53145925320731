import React, { useMemo } from 'react';
import MapBoxList from '../../../../organisms/MapBoxList';
import ContactPageSection from '../../../contact/partials/Section';
import { getMapListItems } from '../../utils';
import { IMapListSection } from '../../types';

type IProps = {
  section: IMapListSection;
};

const MapList: React.FC<IProps> = ({ section }) => {
  const items = useMemo(() => getMapListItems(section.mapList), [section]);

  return (
    <ContactPageSection title={section.title}>
      <MapBoxList items={items} />
    </ContactPageSection>
  );
};

export default MapList;
