import React, { useMemo } from 'react';
import { IRelatedPagesSection } from '../../types';
import { getRelatedPagesItems } from '../../utils';
import PageSection from '../../../Section';
import RelatedPages from '../../../../organisms/RelatedPages';

type IProps = {
  section: IRelatedPagesSection;
};

const RelatedPagesSection: React.FC<IProps> = ({ section }) => {
  const items = useMemo(() => getRelatedPagesItems(section.pagesList), [
    section.pagesList,
  ]);

  return (
    <PageSection>
      <RelatedPages items={items} />
    </PageSection>
  );
};

export default RelatedPagesSection;
