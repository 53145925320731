import React, { useMemo } from 'react';
import RevealAnimationWrapper from '../../../../atoms/RevealAnimationWrapper';
import { ITeamMembersSection } from '../../types';
import { getMembers } from '../../utils';
import PageSection from '../../../Section';
import InfoGrid from '../../../../molecules/InfoGrid';

type IProps = {
  section: ITeamMembersSection;
};

const TeamMembers: React.FC<IProps> = ({ section }) => {
  const members = useMemo(() => getMembers(section.teamMembers), [
    section.teamMembers,
  ]);

  return (
    <PageSection>
      <RevealAnimationWrapper>
        <InfoGrid
          title={section.title}
          items={members}
          columnsCount={[1, 2, 2, 4]}
          columnGap={['5rem', '4.5rem 2rem', '4.5rem 2.5rem', '8rem 2.5rem']}
        />
      </RevealAnimationWrapper>
    </PageSection>
  );
};

export default TeamMembers;
