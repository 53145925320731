/** @jsx jsx */
import { jsx, Box, Text } from 'theme-ui';
import { Link } from 'gatsby';
import Image from '../../atoms/Image';
import { RelatedPageTileProps } from './types';
import { IconEye, IconThunderstrike } from '../../../assets/svg';

const RelatedPageTile: React.FC<RelatedPageTileProps> = ({
  title,
  ctaText,
  to,
  image,
  isWorkRelated,
}) => (
  <Link {...{ to }} sx={{ variant: 'relatedPageTile.wrapper' }}>
    <Box variant="relatedPageTile.wrapper.media">
      <Image ratio={772 / 385} {...image} />
      {isWorkRelated ? (
        <IconEye
          sx={{ variant: 'relatedPageTile.wrapper.media.iconSmall' }}
          className="icon"
        />
      ) : (
        <IconThunderstrike
          sx={{ variant: 'relatedPageTile.wrapper.media.icon' }}
          className="icon"
        />
      )}
    </Box>
    <Box variant="relatedPageTile.wrapper.content">
      <Text as="h4" variant="h4roman" mb={['1rem', '1rem', '2rem']}>
        {title}
      </Text>
      <Text as="span" variant="links.underlined" className="action">
        {ctaText} &gt;
      </Text>
    </Box>
  </Link>
);

export default RelatedPageTile;
