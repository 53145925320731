import React from 'react';
import { Box, Text } from 'theme-ui';
import RevealAnimationWrapper from '../../../../atoms/RevealAnimationWrapper';
import { ContactPageSectionProps } from './types';

const ContactPageSection: React.FC<ContactPageSectionProps> = ({
  title,
  children,
}) => (
  <Box as="section" variant="pages.contact.section">
    <RevealAnimationWrapper cascade>
      <Text as="h2" variant="pages.contact.section.title">
        {title}
      </Text>
      <Box>{children}</Box>
    </RevealAnimationWrapper>
  </Box>
);

export default ContactPageSection;
