export const calcDynamicHeight = (objectWidth: number): number => {
  const vw = window.innerWidth;
  const vh = window.innerHeight;
  return objectWidth - vw + vh + 150;
};

export const handleDynamicHeight = (
  element: HTMLDivElement,
  setDynamicHeight: React.Dispatch<React.SetStateAction<number>>,
): void => {
  const objectWidth = element.scrollWidth;
  const dynamicHeight = calcDynamicHeight(objectWidth);
  setDynamicHeight(dynamicHeight);
};
