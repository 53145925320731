import React from 'react';
import { Box } from 'theme-ui';
import Image from '../../../atoms/Image';
import RevealAnimationWrapper from '../../../atoms/RevealAnimationWrapper';
import Video from '../../Video';
import { InfoGridMediaProps } from '../types';

const InfoGridMedia: React.FC<InfoGridMediaProps> = ({
  url,
  image,
  fluid,
  fixed,
}) => (
  <Box variant={url ? 'infoGrid.mediaVideo' : 'infoGrid.media'}>
    <Box variant="infoGrid.mediaContent">
      <RevealAnimationWrapper>
        <Box>
          {url ? (
            <Video {...{ url, image }} small autoPlay noControls />
          ) : (
            <Image {...{ fluid, fixed }} />
          )}
        </Box>
      </RevealAnimationWrapper>
    </Box>
  </Box>
);

export default InfoGridMedia;
