import React from 'react';
import { Box, Text } from 'theme-ui';

import RevealAnimationWrapper from '../../atoms/RevealAnimationWrapper';

import { HeroProps } from './types';
import CmsContent from '../CmsContent';

const Hero: React.FC<HeroProps> = ({ heading1, heading2, isHeading1Wide }) => (
  <RevealAnimationWrapper cascade>
    <Box variant={isHeading1Wide ? 'hero.wrapperWide' : 'hero.wrapper'}>
      <Text as="h1" variant="h0">
        {heading1}
      </Text>
      <Box mt={['1.25rem', '1.25rem', '2.5rem']}>
        <CmsContent>{heading2}</CmsContent>
      </Box>
    </Box>
  </RevealAnimationWrapper>
);

export default Hero;
