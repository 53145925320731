import React from 'react';
import { Box } from 'theme-ui';
import ContactPageBox from './box';
import { ContactPageBoxListProps } from './types';

const ContactPageBoxList: React.FC<ContactPageBoxListProps> = ({ items }) => (
  <Box variant="pages.contact.box.grid">
    {items.map(({ key, ...item }) => (
      <Box {...{ key }} variant="pages.contact.box.grid.item">
        <ContactPageBox {...item} />
      </Box>
    ))}
  </Box>
);

export default ContactPageBoxList;
