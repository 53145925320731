/* eslint-disable no-nested-ternary */
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Grid } from 'theme-ui';
import { WithTheme } from '../../../gatsby-plugin-theme-ui';
import media from '../../../gatsby-plugin-theme-ui/mediaQueres';
import { InfoGridStyledWrapperProps } from './types';

export const StyledContentGrid = styled(Grid)<
  WithTheme<InfoGridStyledWrapperProps>
>`
  width: 100%;
  ${({ withVideo }) =>
    withVideo
      ? css`
          grid-template-areas: 'video' 'list';
        `
      : css`
          grid-template-areas: 'media' 'list';
        `}
  grid-template-columns: 1fr;
  grid-gap: 5rem;
  @media ${media.phone} {
    grid-template-columns: ${({ reversed }) =>
      reversed ? '1fr 41%' : '41% 1fr'};
    grid-gap: 5rem 3.125rem;
    ${({ reversed, withVideo }) =>
      reversed
        ? withVideo
          ? css`
              grid-template-areas: 'list list' 'video video';
            `
          : css`
              grid-template-areas: 'list media';
            `
        : withVideo
        ? css`
            grid-template-areas: 'video video' 'list list';
          `
        : css`
            grid-template-areas: 'media list';
          `}
  }
  @media ${media.tablet} {
    grid-template-columns: ${({ reversed }) =>
      reversed ? '1fr 41%' : '41% 1fr'};
    grid-gap: 8rem;
    ${({ reversed, withVideo }) =>
      reversed
        ? withVideo
          ? css`
              grid-template-areas: 'list video';
            `
          : css`
              grid-template-areas: 'list media';
            `
        : withVideo
        ? css`
            grid-template-areas: 'video list';
          `
        : css`
            grid-template-areas: 'media list';
          `}
  }
`;
