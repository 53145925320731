import styled from '@emotion/styled';
import { Box, Flex, Text } from 'theme-ui';
import media from '../../../gatsby-plugin-theme-ui/mediaQueres';

export const StyledCarouselItem = styled(Flex)`
  margin-right: 0.5rem;
  flex-shrink: 0;
  @media ${media.tablet} {
    width: calc(50vw - 5rem - 0.5rem);
  }
  @media screen and (min-width: 80rem) and (max-height: 770px) {
    width: calc(50vw - 10rem - 0.5rem);
  }
  @media (min-width: 107rem) {
    width: calc(50vw - ((100vw - 1552px) / 2) - 0.5rem);
  }
`;

export const StyledCarouselContent = styled(Box)`
  height: 100%;
  align-items: center;
  padding: 4rem 0;
  padding-left: calc((100vw - 107rem) / 2);
  margin-left: 1.25rem;
  @media ${media.phone} {
    margin-left: 2.5rem;
  }
  @media ${media.tablet} {
    margin-left: 5rem;
  }
  @media screen and (min-width: 80rem) and (max-height: 770px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
`;

export const StyledCarouselContentWrapper = styled(Flex)`
  @media ${media.tablet} {
    padding: 1rem 0;
  }
  @media ${media.desktop} {
    padding: 4rem 0;
  }
  @media screen and (min-width: 80rem) and (max-height: 770px) {
    padding: 2rem 0;
  }
`;

export const StyledCarouselTitle = styled(Text)`
  @media ${media.phone} {
    margin-bottom: 5rem;
  }
  @media ${media.desktop} {
    margin-bottom: 6rem;
  }
  @media screen and (min-width: 80rem) and (max-height: 770px) {
    margin-bottom: 1rem;
  }
`;
