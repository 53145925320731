import React from 'react';
import { Box, Link, Text } from 'theme-ui';
import ModalBase from '../..';
import Image from '../../../../atoms/Image';
import HTMLWrapper from '../../../HTMLWrapper';
import { TeamMemberModalProps } from './types';

const TeamMemberModal: React.FC<TeamMemberModalProps> = ({
  image,
  name,
  profession,
  description,
  email,
  ...props
}) => (
  <ModalBase withCloseIcon isAlignedRight isHorizontal {...props}>
    <Image ratio={902 / 640} {...image} />
    <Text
      as="h2"
      variant="h1"
      mt={['0.75rem', '0.75rem', '1.5rem']}
      mb={['0.75rem', '0.625rem', '0.5rem']}
    >
      {name}
    </Text>
    <Text as="h3" variant="h4roman" mb={['0.5rem', '0.5rem', '1rem']}>
      {typeof profession === 'string'
        ? profession
        : profession.map((item, index) => (
            <React.Fragment key={item}>
              {item}
              {index + 1 < profession.length && ', '}
            </React.Fragment>
          ))}
    </Text>
    <Box variant="modal.teamMember.cms">
      {description}
      {email && (
        <>
          <Text as="p" mt="2rem">
            Contact {name}
          </Text>
          <Link
            href={`mailto:${email}`}
            variant="underlinedSmall"
            target="_blank"
            rel="noreferrer"
          >
            <HTMLWrapper html={email} />
          </Link>
        </>
      )}
    </Box>
  </ModalBase>
);

export default TeamMemberModal;
