import React, { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import TwitterCarousel from '../../../../organisms/TwitterCarousel';
import { getTwitterItems } from '../../utils';
import { TwitterQuery } from '../../types';

// const query = graphql`
//   query {
//     tweets: allTwitterStatusesUserTimelineLuminous(limit: 3) {
//       nodes {
//         id_str
//         full_text
//         user {
//           screen_name
//         }
//       }
//     }
//   }
// `;

const TwitterSection: React.FC = () => {
  // const { tweets }: TwitterQuery = useStaticQuery(query);

  // const items = useMemo(() => getTwitterItems(tweets.nodes), [tweets]);

  // return <TwitterCarousel items={items} />;
  return <TwitterCarousel items={[]} />;
};

export default TwitterSection;
