import React from 'react';
import { Box, Link, Text } from 'theme-ui';
import { ContactPageBoxItemProps } from './types';
import HTMLWrapper from '../../../../molecules/HTMLWrapper';

const ContactPageBox: React.FC<ContactPageBoxItemProps> = ({
  title,
  items,
}) => (
  <Box variant="pages.contact.box">
    <Text as="h3" variant="pages.contact.box.title">
      {title}
    </Text>
    {items.map(
      ({
        key,
        title: subTitle,
        name,
        profession,
        email,
        phoneNumber,
        zoomLink,
      }) => (
        <Box {...{ key }} variant="pages.contact.box.row">
          {!!subTitle && (
            <Text as="h4" variant="pages.contact.box.subTitle">
              {subTitle}
            </Text>
          )}
          <Text as="p">{name}</Text>
          <Text as="p">{profession}</Text>
          {!!phoneNumber && (
            <>
              {!!zoomLink && (
                <Link
                  href={zoomLink}
                  target="_blank"
                  rel="noreferrer"
                  variant="pages.contact.box.link"
                  className="zoom"
                >
                  <span>{phoneNumber}</span>
                </Link>
              )}
              <Link
                href={`tel:${phoneNumber}`}
                target="_blank"
                rel="noreferrer"
                variant="pages.contact.box.link"
                className="phone"
              >
                <span>{phoneNumber}</span>
              </Link>
            </>
          )}
          {!!email && (
            <Box mt="1.875rem">
              <Link
                href={`mailto:${email}`}
                target="_blank"
                rel="noreferrer"
                variant="underlinedInline"
              >
                <HTMLWrapper html={email} />
              </Link>
            </Box>
          )}
        </Box>
      ),
    )}
  </Box>
);

export default ContactPageBox;
