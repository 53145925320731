/** @jsx jsx */
import React from 'react';
import { jsx, AspectRatio, Box, Grid, Link, Text, Flex } from 'theme-ui';
import { IconPin } from '../../../assets/svg';
import Image from '../../atoms/Image';
import RevealAnimationWrapper from '../../atoms/RevealAnimationWrapper';
import { MapBoxProps } from './types';

const MapBox: React.FC<MapBoxProps> = ({
  title,
  id,
  address,
  phoneNumber,
  zoomLink,
  map,
  href,
  pinPercentagePosition,
}) => (
  <RevealAnimationWrapper>
    <Grid variant="mapBox.wrapper" {...{ id }}>
      <Flex variant="mapBox.details">
        <Text as="h3" variant="mapBox.title">
          {title}
        </Text>
        <Text as="address" variant="mapBox.address">
          {address.map((addressLine, index) => (
            <React.Fragment key={addressLine}>
              {addressLine}
              {index < address.length && <br />}
            </React.Fragment>
          ))}
        </Text>
        {phoneNumber && (
          <Box>
            {zoomLink && (
              <Link
                href={zoomLink}
                target="_blank"
                rel="noreferrer"
                variant="mapBox.link"
                className="zoom"
              >
                {phoneNumber}
              </Link>
            )}
            <Link
              href={`tel:${phoneNumber}`}
              target="_blank"
              rel="noreferrer"
              variant="mapBox.link"
              className="phone"
            >
              {phoneNumber}
            </Link>
          </Box>
        )}
        <Box mt={['1rem', '2.5rem', '2rem']}>
          <Link
            {...{ href }}
            target="_blank"
            rel="noreferrer"
            variant="underlined"
          >
            Google map
          </Link>
        </Box>
      </Flex>
      <Box variant="mapBox.media">
        <AspectRatio ratio={1160 / 655}>
          <IconPin
            sx={{
              top: pinPercentagePosition.y,
              left: pinPercentagePosition.x,
              position: 'absolute',
              height: ['32.3%', '30.9%', '18.3%'],
              width: ['8.6%', '', '5%'],
              zIndex: 1,
            }}
          />
          <Image {...map} />
        </AspectRatio>
      </Box>
    </Grid>
  </RevealAnimationWrapper>
);

export default MapBox;
