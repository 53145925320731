import React, { useMemo } from 'react';
import PageSection from '../../../Section';
import { IInsightsSection } from '../../types';
import ArticlesSection from '../../../../organisms/ArticlesSection';
import { getInsightsItems } from '../../utils';

type IProps = {
  section: IInsightsSection;
};

const Insights: React.FC<IProps> = ({ section }) => {
  const items = useMemo(() => getInsightsItems(section?.insights), [
    section?.insights,
  ]);

  return (
    <PageSection hasSmallSpacing>
      <ArticlesSection
        title={section.title}
        {...{ items }}
        seeAllHref={section?.button?.[0]?.link}
        seeAllText={section?.button?.[0]?.title}
      />
    </PageSection>
  );
};

export default Insights;
