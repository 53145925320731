/** @jsx jsx */
import { useMemo } from 'react';
import { Link } from 'gatsby';
import { Box, Text, jsx } from 'theme-ui';
import RevealAnimationWrapper from '../../../../atoms/RevealAnimationWrapper';
import { ICaseStudiesSection } from '../../types';
import WorkListing from '../../../../organisms/WorkListing';
import { getWorkItems } from '../../utils';
import PageSection from '../../../Section';

type IProps = {
  section: ICaseStudiesSection;
};

const CaseStudies: React.FC<IProps> = ({ section }) => {
  const items = useMemo(() => getWorkItems(section.caseStudies), [
    section.caseStudies,
  ]);

  return (
    <PageSection>
      <RevealAnimationWrapper>
        <Box variant="pages.page.animated">
          <Text variant="h4black" mb="2rem" as="h2">
            {section.title}
          </Text>
        </Box>
      </RevealAnimationWrapper>
      <WorkListing {...{ items }} />
      {section?.button?.length > 0 && (
        <RevealAnimationWrapper>
          <Box mt={['1rem', '1rem', '2rem']}>
            <Link
              to={section.button[0].link}
              sx={{ variant: 'pages.page.link' }}
            >
              {section.button[0].title}
            </Link>
          </Box>
        </RevealAnimationWrapper>
      )}
    </PageSection>
  );
};

export default CaseStudies;
