import { useCallback, useState } from 'react';

const useStatefulRef = <T extends Element>(): [
  (ref: T | null) => void,
  T | null,
] => {
  const [refValue, setRefValue] = useState<T | null>(null);
  const ref = useCallback((element: T | null) => {
    setRefValue(element);
  }, []);

  return [ref, refValue];
};

export default useStatefulRef;
