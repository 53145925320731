/* eslint-disable no-underscore-dangle */
import React from 'react';
import PlainTextWithHighlight from '../../../../custom/PlainTextWithHighlight';
import { IContentText } from '../../types';
import PageSection from '../../../Section';
import HeroSmall from '../../../../molecules/HeroSmall';

type IProps = {
  section: IContentText;
};

const ContentText: React.FC<IProps> = ({ section: { _rawBody, text } }) => (
  <PageSection hasSmallSpacing>
    <HeroSmall
      heading1={<PlainTextWithHighlight blocks={_rawBody} />}
      heading2={text}
    />
  </PageSection>
);

export default ContentText;
