/* eslint-disable no-underscore-dangle */
/** @jsx jsx */
import { Link } from 'gatsby';
import { Box, Text, jsx } from 'theme-ui';
import PlainTextWithHighlight from '../../../../custom/PlainTextWithHighlight';
import RevealAnimationWrapper from '../../../../atoms/RevealAnimationWrapper';
import { IContentButton } from '../../types';
import PageSection from '../../../Section';

type IProps = {
  section: IContentButton;
};

const ContentButton: React.FC<IProps> = ({ section: { _rawBody, button } }) => (
  <PageSection hasSmallSpacing>
    <RevealAnimationWrapper cascade>
      <Box variant="pages.page.offer">
        <Text as="h2" variant="h2" mt="2.5rem">
          <PlainTextWithHighlight blocks={_rawBody} />
        </Text>
        {button?.length > 0 && (
          <Box mt={['1rem', '1.25rem', '2rem']}>
            <Link to={button[0].link} sx={{ variant: 'pages.page.link' }}>
              {button[0]?.title}
            </Link>
          </Box>
        )}
      </Box>
    </RevealAnimationWrapper>
  </PageSection>
);

export default ContentButton;
