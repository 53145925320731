import React from 'react';
import { Box, Container, Flex, Image, Text } from 'theme-ui';
import CmsContent from '../../../molecules/CmsContent';
import { CmsContentType } from '../../../molecules/CmsContent/enums';
import { DismissableSectionContentProps } from './types';

const DismissableSectionContent: React.FC<DismissableSectionContentProps> = ({
  category,
  content,
  imageSrc,
  imageAlt,
  whiteColorText,
}) => (
  <>
    <Image
      src={imageSrc}
      alt={imageAlt}
      variant="dismissableSection.content.media"
    />
    <Box
      variant="dismissableSection.content.wrapper"
      className={whiteColorText ? 'is-light' : ''}
    >
      <Flex variant="dismissableSection.content.main">
        <Container>
          <Box variant="dismissableSection.content.inner">
            <Text as="h4" variant="dismissableSection.content.category">
              {category}
            </Text>
            <CmsContent type={CmsContentType.customLink}>{content}</CmsContent>
          </Box>
        </Container>
      </Flex>
    </Box>
  </>
);

export default DismissableSectionContent;
